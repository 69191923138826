import React, { useCallback, useState } from "react";
import "./index.css";
import api from "../Api/Api";
import { Toaster, toast } from "react-hot-toast";
import { debounce } from "lodash";

const Switch = ({
  isOn,
  handleToggle,
  id,
  is_active,
  handleStatusaftercall,
}) => {
  const { Change_meeting_status } = api();
  const [loading, setLoading] = useState(false);

  const handleMeetingStatus = async ({ id, is_active }) => {
    setLoading(true);
    const activity = is_active === 1 ? 0 : 1;
    try {
      const response = await Change_meeting_status({
        video_id: id,
        is_active: activity,
      });
      await handleStatusaftercall();
      toast.success(response.message);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const debouncedHandleMeetingStatus = useCallback(
    debounce(handleMeetingStatus, 300), // 300ms debounce
    []
  );
  return (
    <div className="switch-container">
      {loading && (
        <>
          <div className="spinner-overlay">
            <div className="spinner-container"></div>
          </div>
        </>
      )}
      <input
        checked={is_active === 1}
        onChange={() => {
          handleToggle({ id, is_active });
          debouncedHandleMeetingStatus({ id, is_active });
        }}
        className="react-switch-checkbox"
        id={`react-switch-${id}`}
        type="checkbox"
      />
      <label
        style={{ background: is_active === 1 ? "#06D6A0" : "grey" }}
        className="react-switch-label"
        htmlFor={`react-switch-${id}`}
      >
        <span className={`react-switch-button`} />
      </label>
      {/* <button
        className={
          is_active === 1
            ? ""
            : ""
        }
      >
        {is_active === 1 ? "active " : "unactive "}
      </button> */}
    </div>
  );
};

export default Switch;
