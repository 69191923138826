import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Api from "../Api/Api";
import toast, { Toaster } from "react-hot-toast";
import eyeIcon from "../../assets/images/dev-eye.svg";
import eyeIcon2 from "../../assets/images/dev-slash-eye.svg";
function ChangePassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    clearErrors,
  } = useForm();
  const { ChangePassword } = Api();

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "current":
        setShowCurrentPassword(!showCurrentPassword);
        break;
      case "new":
        setShowNewPassword(!showNewPassword);
        break;
      case "confirm":
        setShowConfirmNewPassword(!showConfirmNewPassword);
        break;
      default:
        break;
    }
  };

  const onSubmit = async (data) => {
    try {
      if (data.newpassword !== data.confirmnewpassword) {
        // Passwords don't match, show an alert
        toast.error("New password and Confirm New password should match");
        return; // Exit early
      }
      const result = await ChangePassword(data);

      if (result.success) {
        toast.success(result.message);
      } else {
        // Handle login failure
        toast.error(result.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <form
        className="profile_form change_password"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 mb-2">
            <div class="">
              <label for="currentpassword" class="form-label">
                Current Password
              </label>
              <div className="input_from position-relative input_from-password">
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  className="form-control"
                  id="currentpassword"
                  placeholder="Enter Your Password"
                  name="currentpassword"
                  onKeyPress={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  {...register("currentpassword", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                  })}
                />
                {errors.currentpassword && (
                  <span className="error_message">
                    {errors.currentpassword.message}
                  </span>
                )}
                {/* {errors.currentpassword && <span className="invalid-feedback">Current Password is required</span>} */}
                <span
                  className="text_visible"
                  onClick={() => togglePasswordVisibility("current")}
                >
                  {showCurrentPassword ? (
                    <img src={eyeIcon} alt="hide" />
                  ) : (
                    <img src={eyeIcon2} alt="show" />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 mb-2">
            <div class="">
              <label for="newpassword" class="form-label">
                New Password
              </label>
              <div className="input_from position-relative input_from-password">
                <input
                  type={showNewPassword ? "text" : "password"}
                  className="form-control"
                  id="newpassword"
                  placeholder="Enter New Password"
                  name="newpassword"
                  onKeyPress={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  {...register("newpassword", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters long",
                    },
                  })}
                />
                {errors.newpassword && (
                  <span className="error_message">
                    {errors.newpassword.message}
                  </span>
                )}
                {/* {errors.newpassword && <span className="invalid-feedback">New Password is required</span>} */}

                <span
                  className="text_visible"
                  onClick={() => togglePasswordVisibility("new")}
                >
                  {showCurrentPassword ? (
                    <img src={eyeIcon} alt="hide" />
                  ) : (
                    <img src={eyeIcon2} alt="show" />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6 mb-2">
            <div class="">
              <label for="confirmnewpassword" class="form-label">
                Confirm New Password
              </label>
              <div className="input_from position-relative input_from-password">
                <input
                  type={showConfirmNewPassword ? "text" : "password"}
                  className="form-control"
                  id="confirmnewpassword"
                  placeholder="Enter Confirm New Password"
                  name="confirmnewpassword"
                  onKeyPress={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  {...register("confirmnewpassword", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message:
                        "Password must be at least 8 characters long",
                    },
                  })}
                />
                {errors.confirmnewpassword && (
                  <span className="error_message">
                    {errors.confirmnewpassword.message}
                  </span>
                )}
                {/* {errors.confirmnewpassword && <span className="invalid-feedback">Confirm New Password is required</span>} */}
                <span
                  className="text_visible"
                  onClick={() => togglePasswordVisibility("confirm")}
                >
                  {showCurrentPassword ? (
                    <img src={eyeIcon} alt="hide" />
                  ) : (
                    <img src={eyeIcon2} alt="show" />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-3">
            <div class="texts-end">
              <button className="btn form_save-btn">Save</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
