import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import filter_icon from "../../assets/images/dev-filter-icon.svg";
import reload_icon from "../../assets/images/dev-reload_icon.svg";
import "bootstrap-datepicker";
import "../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import playIcon from "../../assets/images/dev-play_icon.svg";
import fbIcon from "../../assets/images/dev-fb_icon.svg";
import twitterIcon from "../../assets/images/dev-twitter_icon.svg";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { IoClose, IoCloseCircle } from "react-icons/io5";
import Api from "../Api/Api";
import { CiSearch } from "react-icons/ci";
import Datepickercomponent from "../util/Datepicker";
function Reports() {
  const moment = require("moment");
  const [isVisible, setIsVisible] = useState(false);
  const [MeetingData, setMeetingData] = useState([]);
  const [PerPageCount, setPerPageCount] = useState("");
  const [StartCount, setStartCount] = useState("");
  const [TotalCount, setTotalCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  console.log(currentPage);
  const [meeting, setMeeting] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectfromdate, setSelectedDate] = useState(null);
  const [selecttodate, setselectTodate] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [fromtimevalue, setFromtimevalue] = useState(null);
  const [totimevalue, setTotimevalue] = useState(null);
  const rowsPerPage = 10;
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = Math.max(indexOfLastRow - rowsPerPage, 0);
  // const currentRows = MeetingData.slice(indexOfFirstRow, indexOfLastRow);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };

  const handleNextPage = async () => {
    setCurrentPage(currentPage + 1);
    if (currentPage >= 1) {
      handleFilterChange({
        meeting: meeting,
        selectfromdate: selectfromdate,
        selecttodate: selecttodate,
        currentPage: currentPage + 1,
      });
    }
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (currentPage > 1) {
      handleFilterChange({
        meeting: meeting,
        selectfromdate: selectfromdate,
        selecttodate: selecttodate,
        currentPage: currentPage - 1,
      });
    }
    if (currentPage === 0) {
      setCurrentPage(1);
    }
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };

  const { ReportVideoList } = Api();

  const dataTableRef = useRef(null);
  const fromDateRef = useRef(null);
  const toDateRef = useRef(null);
  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
      setCurrentPage(0);
      handleFilterChange({
        meeting: meeting,
        selectfromdate: date,
        selecttodate: selecttodate,
        currentPage: currentPage,
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to reset the overflow when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleTodate = (date) => {
    setselectTodate(date);
    setCurrentPage(1);
    handleFilterChange({
      meeting: meeting,
      selectfromdate: selectfromdate,
      selecttodate: date,
      currentPage: currentPage,
    });
  };

  const handleMeeting = (event) => {
    setMeeting(event.target.value);
    setCurrentPage(1);
    handleFilterChange({
      meeting: event.target.value,
      currentPage: "1",
      selectfromdate: null,
      selecttodate: null,
    });
  };

  const [first, setFirst] = useState(true);
  const handlePage = async () => {
    if (first) {
      try {
        const filters = {
          meeting: "",
          selectfromdate: "",
          selecttodate: "",
          currentPage: "1",
        };

        const response = await ReportVideoList(filters);
        if (response) {
          setFirst(false);
        }
        console.log("MeetingList--data", response.parameters.data);
        // setVideoData(response.parameters.data);
        setMeetingData(response.parameters.data);
        setTotalCount(response.parameters.total);
        setPerPageCount(response.parameters.to);
        setStartCount(response.parameters.from);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setFirst(true);
        console.error("Error occurred during filtering meetings:", error);
      }
    }
  };
  useEffect(() => {
    handlePage();
  }, [first]);

  const handleFilterChange = async ({
    meeting,
    currentPage,
    selectfromdate,
    selecttodate,
  }) => {
    try {
      // const statusValue = document.getElementById('status').value;
      setLoading(true);
      const fromDate = selectfromdate
        ? moment(selectfromdate).format("DD/MM/YYYY")
        : "";
      console.log(fromDate);

      const toDate =
        selecttodate && moment(selecttodate).isValid()
          ? moment(selecttodate).format("DD/MM/YYYY")
          : "";
      const filters = {
        meeting: meeting,
        // currentPage: "0",
        selectfromdate: fromDate,
        selecttodate: toDate,
        page: currentPage.toString(),
      };

      const response = await ReportVideoList(filters);
      console.log("Liked", response.parameters.data);
      setMeetingData(response.parameters.data);
      setTotalCount(response.parameters.total);
      setPerPageCount(response.parameters.to);
      setStartCount(response.parameters.from);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error occurred during filtering meetings:", error);
    }
  };

  const handleResetFilters = () => {
    setMeeting("");
    setSelectedDate(null);
    setselectTodate(null);

    setCurrentPage(1);
    handleFilterChange({
      meeting: "",
      selectfromdate: null,
      selecttodate: null,
      currentPage: "1",
    });
    setTotimevalue(null);
    setFromtimevalue(null);
  };
  return (
    <div className="page_container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb"></div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mob_fil_align">
              <div className="page_title">Reports</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="mob_fil_align">
              <div className="page_title title_wise py-3">
                Meeting Title wise Statistics
              </div>
              <div
                className="mobile_filter_icon mob_fills_icon"
                onClick={toggleVisibility}
              >
                <img src={filter_icon} alt="filter" />
              </div>
            </div>
          </div>
        </div>
        <div className="row desktop_filter">
          <div className="col-12">
            <form>
              <div className={`filter_container ${isOpen ? "open" : ""}`}>
                <div
                  className="filter_icon filter_col sm_flex-end hide_mobile"
                  onClick={toggleVisibility}
                >
                  <img src={filter_icon} alt="filter" className="" />
                  {/* <IoClose className="show_mobile filter_close" /> */}
                </div>

                <div
                  className="close_sidemenu_mobile"
                  onClick={toggleVisibility}
                >
                  {isOpen && (
                    <IoCloseCircle
                      className="close_icon_mobile_response"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    />
                  )}
                </div>
                <div className="fw-bold filter_title mobile-mt-3 filter_col d-flex align-items-center justify-content-center">
                  Filter By
                </div>
                {/* <div className="status_filter filter_col">
                  <select
                    className="form-control"
                    id="status"
                    name="status"
                    onChange={handleFilterChange}
                  >
                    <option value="">Status</option>
                    <option value="UPCOMING">Upcoming</option>
                    <option value="COMPLETED">Completed</option>
                    <option value="IN-PROGRESS">In-progress</option>
                  </select>
                </div> */}
                <div className="status_filter filter_col">
                  <div className="name_filter position-relative">
                    <input
                      type="text"
                      placeholder="Title"
                      name="title"
                      value={meeting}
                      onChange={handleMeeting}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault(); // Prevent default behavior
                        }
                      }}
                    />
                    <span>
                      <CiSearch />
                    </span>
                  </div>
                </div>
                <div className="from_date-filter filter_col text-center">
                  <Datepickercomponent
                    selecteddate={selectfromdate}
                    handleDate={handleDateChange}
                    placeholderText="From Date"
                  />
                </div>
                <div className="to_date-filter filter_col text-center">
                  <Datepickercomponent
                    selecteddate={selecttodate}
                    handleDate={handleTodate}
                    placeholderText="To Date"
                  />
                </div>
                {/* <div className="from_time-filter filter_col">
                  <input
                    type="time"
                    name="FromTime"
                    className="form-control"
                    onChange={handleFilterChange}
                  />
                </div> */}
                {/* <div className="to_time-filter filter_col">
                  <input
                    type="time"
                    name="ToTime"
                    className="form-control"
                    onChange={handleFilterChange}
                  />
                </div> */}

                <div
                  className="reset_filter filter_col d-flex align-items-center justify-content-center"
                  onClick={handleResetFilters}
                >
                  <img src={reload_icon} alt="reset" className="me-2" /> Reset
                  Filter
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="row mob_desk_margin">
          <div className="col-12">
            <div className="table-responsive main_datatable">
              <table
                ref={dataTableRef}
                className="table custom_datatable meeting_table"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>ID</th>
                    <th className="text-start">MEETING TITLE</th>
                    <th className="text-start">REPORT TITLE</th>
                    <th className="text-start">REPORTED EMAIL</th>
                    <th className="text-start">CREATED DATE</th>
                    <th className="text-center">COMMENTS</th>
                  </tr>
                </thead>

                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan="7" className="text-center">
                        Loading...
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {MeetingData && MeetingData.length > 0 ? (
                      MeetingData.map((meet, index) => (
                        <tr key={meet.id}>
                          <td className="text-center">
                            {indexOfFirstRow + index + 1}
                          </td>
                          <td className="text-start">
                            {" "}
                            <Link
                              to={`/admin/reports/meeting-detail/${meet.video_id}`}
                            >
                              {meet.video_title}
                            </Link>
                          </td>
                          <td className="text-start">{meet.report_title}</td>
                          <td className="text-start">{meet.email}</td>
                          <td className="text-center">
                            {moment(meet.created_at).format("YYYY-MM-DD")}
                          </td>
                          <td className="text-center">
                            {meet.comments || "-"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No meetings found for the selected filters.
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
            {MeetingData.length > 0 && (
              <div className="d-flex align-items-center justify-content-between">
                <p className="m-0 table_page-count">
                  Showing {StartCount}-{PerPageCount} of {TotalCount}
                </p>
                <div className="d-flex align-items-center justify-content-end table_nav">
                  <button disabled={currentPage === 1} onClick={handlePrevPage}>
                    <FaAngleLeft />
                  </button>
                  <button
                    disabled={PerPageCount == TotalCount}
                    onClick={handleNextPage}
                  >
                    <FaAngleRight />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reports;
