import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../assets/images/dev-parliament-logo.png";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import Api from "../Api/Api";
import "../OTP/otp.css";
import Otptimer from "./Resendotp";
function OTP({ setlogged_in }) {
  const navigate = useNavigate();
  const { VerifyOtp, Resend_otp } = Api();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm();
  const location = useLocation();
  const isAdminLogin = location.pathname === "/admin/forgot-password";
  const user_role = localStorage.getItem("user_role");
  const user_email = localStorage.getItem("email_id");
  const currentTime = Date.now();
  //resend otp timer
  const [expiryTime, setExpiryTime] = useState(Date.now() + 30000); // Expiry time set to 1 minute from now
  console.log(expiryTime);
  // Function to resend OTP and update the expiry time
  const [resendTimer, setResendtimer] = useState(false);
  const [resend, setResend] = useState(false);
  const handleResendOTP = () => {
    setExpiryTime(Date.now() + 30000);
    setResend(true);
    setResendtimer(true);
  };

  // useEffect(() => {
  //   setInterval(() => {
  //     setResend(false);
  //   }, 30000);
  // }, [currentTime]);

  const onSubmit = async (data) => {
    try {
      const response = await VerifyOtp(data);

      if (response.success) {
        toast.success(response.message);
        navigate("/");
      } else {
        console.log("Forgot password failed");
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Error occurred during login:", error.message);
    }
  };

  const handleResendotp = async () => {
    handleResendOTP();
    try {
      const response = await Resend_otp({ email: user_email });
      console.log(response);
      if (response) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.log("Error message:", error.message);
    }
  };

  return (
    <div className="main_body">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-6">
            <div className="form_container">
              <div className="login_logo mb-3 text-center">
                <img src={logo} alt="logo" />
              </div>
              <div className="login_form-inner">
                <div className="login_title">Verify your Email Address</div>
                <div className="login_sm-title mb-2">{user_email}</div>
                <div className="verify_email text-center mb-3">
                  <div className="have_login text-center mb-3">
                    <Link to="/register">Change Email</Link>
                  </div>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="input_from position-relative">
                    <input
                      className="text-center otp_input"
                      type="text"
                      placeholder="Enter Your 4 Digit OTP Number"
                      name="otp"
                      maxLength={4}
                      onKeyPress={(event) => {
                        // Allow only numbers (0-9) and prevent other characters
                        const keyCode = event.charCode || event.keyCode;
                        const allowedKeys = /[0-9]/;
                        const allowedKey = allowedKeys.test(
                          String.fromCharCode(keyCode)
                        );
                        if (!allowedKey) {
                          event.preventDefault();
                        }
                      }}
                      {...register("otp", {
                        required: "Please enter OTP Number.",
                        pattern: {
                          value: /^[0-9]{4}$/, // This pattern allows only 4 digits (0-9)
                          message: "Please enter a valid 4-digit OTP number.",
                        },
                      })}
                    />
                    {errors.otp && (
                      <span className="error_message">
                        {errors.otp.message}
                      </span>
                    )}
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="login_submit btn w-100 text-white"
                    >
                      Verify OTP
                    </button>
                  </div>
                </form>

                <div className="">
                  {resendTimer && (
                    <Otptimer expiryTime={expiryTime} setResend={setResend} />
                  )}
                  <div className="have_login text-center my-4">
                    <button
                      onClick={handleResendotp}
                      className={`${
                        resend ? "resend_otpbtn_false" : "resend_otpbtn"
                      } `}
                      disabled={resend}
                    >
                      Resend OTP
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="copy_right text-center">
                                Copyright 2024. All Rights Reserved
                            </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OTP;
