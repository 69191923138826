import React, { useEffect, useRef, useState } from "react";
import "./meetings.css";
import $ from "jquery";
// import 'datatables.net-bs4';
// import '../../../node_modules/datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import filter_icon from "../../assets/images/dev-filter-icon.svg";
import reload_icon from "../../assets/images/dev-reload_icon.svg";
import "bootstrap-datepicker";
import "../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import playIcon from "../../assets/images/dev-play_icon.svg";
import fbIcon from "../../assets/images/dev-fb_icon.svg";
import twitterIcon from "../../assets/images/dev-twitter_icon.svg";
import { Link } from "react-router-dom";
import { LiaTimesSolid } from "react-icons/lia";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { IoClose, IoCloseCircle } from "react-icons/io5";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import "@progress/kendo-theme-default/dist/all.css";
import Api from "../Api/Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Datepickercomponent from "../util/Datepicker";
import moment from "moment";
function Meetings() {
  const moment = require("moment");
  const [isVisible, setIsVisible] = useState(false);
  const [MeetingData, setMeetingData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // console.log(currentPage);
  const [TotalCount, setTotalCount] = useState("");
  const [PerPageCount, setPerPageCount] = useState("");
  const rowsPerPage = 10;
  const indexOfLastRow = currentPage * rowsPerPage;
  // const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const indexOfFirstRow = Math.max(indexOfLastRow - rowsPerPage, 0);
  const currentRows = MeetingData.slice(indexOfFirstRow, indexOfLastRow);
  const [isOpen, setIsOpen] = useState(false);
  const user_role = localStorage.getItem("user_role");
  const [loading, setLoading] = useState(false);
  const [StartCount, setStartCount] = useState("");
  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };

  const { MeetingList } = Api();

  const handleClose = () => {
    setIsVisible(false);
  };

  const dataTableRef = useRef(null);
  //   const fromDateRef = useRef(null);
  //   const toDateRef = useRef(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectfromdate, setSelectedDate] = useState(null);
  const [selecttodate, setselectTodate] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [fromtimevalue, setFromtimevalue] = useState(null);
  const [totimevalue, setTotimevalue] = useState(null);

  const handleNextPage = async () => {
    setCurrentPage(currentPage + 1);
    if (currentPage >= 1) {
      handleFilterChange({
        status: selectedStatus,
        currentPage: currentPage + 1,
        fromTime: fromTime,
        toTime: toTime,
        selectfromdate: selectfromdate,
        selecttodate: selecttodate,
      });
    }
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to reset the overflow when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);
  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (currentPage > 1) {
      handleFilterChange({
        status: selectedStatus,
        currentPage: currentPage - 1,
        fromTime: fromTime,
        toTime: toTime,
        selectfromdate: selectfromdate,
        selecttodate: selecttodate,
      });
    }
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };

  const handleDateChange = (date) => {
    console.log(date);
    if (date) {
      // setSelectedDate(null);
      // const formatdate = moment(date).format("DD/MM/YY");
      setSelectedDate(date);
      setCurrentPage(0);
      handleFilterChange({
        status: selectedStatus,
        fromTime: fromTime,
        toTime: toTime,
        selectfromdate: date,
        selecttodate: selecttodate,
        currentPage: currentPage,
      });
    }
  };

  const handleTodate = (date) => {
    // const formatdate = moment(date).format("DD/MM/YY");
    setselectTodate(date);
    setCurrentPage(0);
    handleFilterChange({
      status: selectedStatus,
      fromTime: fromTime,
      toTime: toTime,
      selectfromdate: selectfromdate,
      selecttodate: date,
      currentPage: currentPage,
    });
  };

  //   useEffect(() => {
  //     $(fromDateRef.current).datepicker().on("change", handleFromDateChange);
  //     $(toDateRef.current).datepicker().on("change", handleToDateChange);
  //   }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       await handleFilterChange({
  //         fromTime: fromTime,
  //         toTime: toTime,
  //         selectfromdate: selectfromdate,
  //         selecttodate: selecttodate,
  //       });
  //     } catch (error) {
  //       console.error("Error occurred while fetching data:", error);
  //     }
  //   };
  //   fetchData();
  // }, [currentPage]);

  const defaultTime = new Date();
  defaultTime.setHours(0);
  defaultTime.setMinutes(0);

  const handleFromTimeChange = (event) => {
    const selectedTime = event.target.value;
    setFromtimevalue(selectedTime);
    console.log(selectedTime);
    // const formattedTime = selectedTime.toLocaleTimeString([], {
    //   hour: "2-digit",
    //   minute: "2-digit",
    // });
    const formattedTime = moment(selectedTime, ["h:mm A"]).format("HH:mm");
    setFromTime(formattedTime);
    handleFilterChange({
      status: selectedStatus,
      fromTime: formattedTime,
      toTime: toTime,
      selectfromdate: selectfromdate,
      selecttodate: selecttodate,
      currentPage: currentPage,
    });
  };

  const handleToTimeChange = (event) => {
    const selectedTime = event.target.value;
    setTotimevalue(selectedTime);
    // const formattedTime = selectedTime.toLocaleTimeString([], {
    //   hour: "2-digit",
    //   minute: "2-digit",
    // });
    const formattedTime = moment(selectedTime, ["h:mm A"]).format("HH:mm");
    setToTime(formattedTime);
    handleFilterChange({
      status: selectedStatus,
      fromTime: fromTime,
      toTime: formattedTime,
      selectfromdate: selectfromdate,
      selecttodate: selecttodate,
      currentPage: currentPage,
    });
  };

  const handleStatus = async (event) => {
    setSelectedStatus(event.target.value);
    console.log(event.target.value);
    await handleFilterChange({
      status: event.target.value,
      fromTime: fromTime,
      toTime: toTime,
      selectfromdate: selectfromdate,
      selecttodate: selecttodate,
      currentPage: currentPage,
    });
  };

  const handleFilterChange = async ({
    currentPage,
    selectfromdate,
    selecttodate,
    fromTime,
    toTime,
    status,
  }) => {
    try {
      console.log(currentPage);
      // const statusValue = document.getElementById("status").value;
      setLoading(true);
      console.log(selectfromdate);
      const fromDate = selectfromdate
        ? moment(selectfromdate).format("DD/MM/YYYY")
        : "";
      console.log(fromDate);

      const toDate =
        selecttodate && moment(selecttodate).isValid()
          ? moment(selecttodate).format("DD/MM/YYYY")
          : "";
      console.log(toDate);
      // debugger;
      // const isFiltersEmpty =
      //   !statusValue && !fromDate && !toDate && !fromTime && !toTime;
      // console.log(isFiltersEmpty);
      const filters = {
        status: status,
        fromDate: fromDate,
        toDate: toDate,
        fromTime: fromTime ? fromTime : "",
        toTime: toTime ? toTime : "",
        // page: isFiltersEmpty ? currentPage.toString() : "0",
        page: currentPage.toString(),
      };

      const response = await MeetingList(filters);
      console.log("MeetingList--data", response.parameters.from);
      setMeetingData(response.parameters.data);
      setTotalCount(response.parameters.total);
      setPerPageCount(response.parameters.to);
      setStartCount(response.parameters.from);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error occurred during filtering meetings:", error);
    }
  };

  const [first, setFirst] = useState(true);
  const handlePage = async () => {
    if (first) {
      try {
        const filters = {
          status: "",
          fromDate: "",
          toDate: "",
          fromTime: "",
          toTime: "",
          page: "0",
        };

        const response = await MeetingList(filters);
        if (response) {
          setFirst(false);
        }
        console.log("MeetingList--data", response.parameters.data);
        // setVideoData(response.parameters.data);
        setMeetingData(response.parameters.data);
        setTotalCount(response.parameters.total);
        setPerPageCount(response.parameters.to);
        setStartCount(response.parameters.from);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setFirst(true);
        console.error("Error occurred during filtering meetings:", error);
      }
    }
  };
  useEffect(() => {
    handlePage();
  }, [first]);
  //   useEffect(() => {
  //     $(fromDateRef.current).datepicker();
  //     $(toDateRef.current).datepicker();
  //     // GetMeetings();
  //     handleFilterChange(currentPage);
  //   }, [currentPage]);

  const handleResetFilters = () => {
    // ;
    // fromDateRef.current.value = "";
    // toDateRef.current.value = "";
    // document.getElementById("status").value = "";
    // document.getElementsByName('FromTime')[0].value = "00:00";
    // document.getElementsByName('ToTime')[0].value = "00:00";
    // defaultTime.setHours(0);
    // defaultTime.setMinutes(0);
    setSelectedStatus("");
    setSelectedDate(null);
    setselectTodate(null);
    setFromTime(null);
    setToTime(null);
    setCurrentPage(0);
    handleFilterChange({
      status: "",
      fromTime: null,
      toTime: null,
      selectfromdate: null,
      selecttodate: null,
      currentPage: "0",
    });
    setTotimevalue(null);
    setFromtimevalue(null);
  };

  return (
    <div className="page_container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb">
              {/* <ul>
                <li className="active">
                  <Link to="#">Home</Link>
                </li>
                <li>Meetings</li>
              </ul> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="mob_fil_align">
              <div className="page_title">Previous Meetings</div>
              <div
                className="mobile_filter_icon mob_fills_icon"
                onClick={toggleVisibility}
              >
                <img src={filter_icon} alt="filter" />
              </div>
            </div>
          </div>
        </div>

        <div className="row desktop_filter">
          <div className="col-12">
            <form>
              <div className={`filter_container ${isOpen ? "open" : ""}`}>
                <div
                  className="filter_icon filter_col sm_flex-end hide_mobile"
                  onClick={toggleVisibility}
                >
                  <img src={filter_icon} alt="filter" className="" />
                </div>

                <div
                  className="close_sidemenu_mobile"
                  onClick={toggleVisibility}
                >
                  {isOpen && (
                    <IoCloseCircle
                      className="close_icon_mobile_response"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    />
                  )}
                </div>
                <div className="fw-bold filter_title filter_col d-flex align-items-center justify-content-center">
                  Filter By
                </div>
                <div className="status_filter filter_col">
                  <select
                    className="form-control"
                    id="status"
                    name="status"
                    onChange={handleStatus}
                  >
                    <option value="">Status</option>
                    <option value="UPCOMING">Upcoming</option>
                    <option value="COMPLETED">Completed</option>
                    <option value="LIVE">Live</option>
                  </select>
                </div>
                <div className="from_date-filter filter_col date_picker">
                  {/* <input
                                        ref={fromDateRef}
                                        type="text"
                                        className="form-control border-0"
                                        placeholder="From Date"
                                        name='FromDate'
                                        onChange={handleFromDateChange}
                                    /> */}
                  {/* <DatePicker
                    selected={selectfromdate}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/YYYY"
                    placeholderText="From Date"
                  /> */}
                  <Datepickercomponent
                    selecteddate={selectfromdate}
                    handleDate={handleDateChange}
                    placeholderText="From Date"
                  />
                </div>
                <div className="to_date-filter filter_col date_picker">
                  {/* <input
                    ref={toDateRef}
                    type="text"
                    className="form-control border-0"
                    placeholder="To Date"
                    name="ToDate"
                    onChange={handleToDateChange}
                  /> */}
                  {/* <DatePicker
                    selected={selecttodate}
                    onChange={handleTodate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="To Date"
                  /> */}
                  <Datepickercomponent
                    selecteddate={selecttodate}
                    handleDate={handleTodate}
                    placeholderText="To Date"
                  />
                </div>
                <div className="from_time-filter filter_col p-0 down_arrow-none">
                  {/* <input type="time" name='FromTime' className="form-control" onChange={handleFilterChange} /> */}
                  <TimePicker
                    title={fromTime}
                    value={fromtimevalue}
                    format="HH:mm"
                    placeholder="From Time"
                    name="FromTime"
                    className="form-control border-0 h-100 w-100"
                    onChange={handleFromTimeChange}
                  />
                </div>
                <div className="to_time-filter filter_col p-0 down_arrow-none">
                  {/* <input type="time" name='ToTime' className="form-control" onChange={handleFilterChange} /> */}
                  <TimePicker
                    title={toTime}
                    value={totimevalue}
                    format="HH:mm"
                    placeholder="To Time"
                    name="ToTime"
                    className="form-control border-0 h-100 w-100"
                    onChange={handleToTimeChange}
                  />
                </div>
                <div
                  className="reset_filter filter_col d-flex align-items-center justify-content-center"
                  onClick={handleResetFilters}
                >
                  <img src={reload_icon} alt="reset" className="me-2" /> Reset
                  Filter
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="row mob_desk_margin">
          <div className="col-12">
            <div className="table-responsive main_datatable">
              <table
                ref={dataTableRef}
                className="table custom_datatable meeting_table"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th className="text-center">ID</th>
                    <th className="text-start">DATE</th>
                    <th className="text-start">TITLE</th>
                    <th className="text-center">START TIME</th>
                    <th className="text-center">END TIME</th>
                    <th className="text-center">STATUS</th>
                    <th className="text-center">ACTION</th>
                  </tr>
                </thead>

                {loading ? (
                  // Loader component or loading indicator
                  <tbody>
                    <tr>
                      <td colSpan="7" className="text-center">
                        Loading...
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {MeetingData && MeetingData.length ? (
                      MeetingData.map((meet, index) => (
                        <tr key={meet.id}>
                          <td className="text-center">
                            {indexOfFirstRow + index + 1}
                          </td>
                          <td className="text-start white-space-nowrap">
                            {meet.video_date}
                          </td>
                          <td className="text-start">
                            <Link
                              to={
                                user_role === "ADMIN"
                                  ? `/admin/meetings/meeting-detail/${meet.id}`
                                  : `/user/meetings/meeting-detail/${meet.id}`
                              }
                            >
                              {" "}
                              {meet.video_title && meet.video_title}
                            </Link>
                          </td>
                          <td className="text-center">
                            {/* {meet.from_time.substring(0, 5)} */}
                            {meet.from_time ? (
                              <>
                                {meet.from_time.split(":")[0]}:
                                {meet.from_time.split(":")[1]}
                              </>
                            ) : (
                              "00:00"
                            )}
                          </td>
                          <td className="text-center">
                            {/* {meet.to_time.substring(0, 5)} */}
                            {meet.to_time ? (
                              <>
                                {meet.to_time.split(":")[0]}:
                                {meet.to_time.split(":")[1]}
                              </>
                            ) : (
                              "00:00"
                            )}
                          </td>
                          <td className="text-center">
                            <button
                              className={
                                meet.status === "COMPLETED"
                                  ? "btn custom_btn-success ws-75"
                                  : meet.status === "UPCOMING"
                                  ? "btn custom_btn-info ws-75"
                                  : "btn custom_btn-warning ws-75"
                              }
                            >
                              {meet.status === "COMPLETED"
                                ? "Completed"
                                : meet.status === "UPCOMING"
                                ? "Upcoming"
                                : "Live"}
                            </button>
                          </td>
                          <td>
                            <ul className="action_list">
                              {meet.youtube_link && (
                                <li>
                                  <Link
                                    to={meet.youtube_link && meet.youtube_link}
                                    target="_blank"
                                  >
                                    <img src={playIcon} alt="play" />
                                  </Link>
                                </li>
                              )}
                              {meet.facebook_link && (
                                <li>
                                  <Link
                                    to={
                                      meet.facebook_link && meet.facebook_link
                                    }
                                    target="_blank"
                                  >
                                    <img src={fbIcon} alt="facebook" />
                                  </Link>
                                </li>
                              )}
                              {meet.twitter_link && (
                                <li>
                                  <Link
                                    to={meet.twitter_link && meet.twitter_link}
                                    target="_blank"
                                  >
                                    <img src={twitterIcon} alt="twitter" />
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No meetings found for the selected filters.
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
            {MeetingData && MeetingData.length > 0 && (
              <div className="d-flex align-items-center justify-content-between">
                <p className="m-0 table_page-count">
                  Showing {StartCount}-{PerPageCount} of {TotalCount}
                </p>
                <div className="d-flex align-items-center justify-content-end table_nav">
                  <button disabled={currentPage === 1} onClick={handlePrevPage}>
                    <FaAngleLeft />
                  </button>
                  <button
                    disabled={PerPageCount == TotalCount}
                    onClick={handleNextPage}
                  >
                    <FaAngleRight />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Meetings;
