import React, { useEffect, useRef, useState } from "react";
import "./settings.css";
import filter_icon from "../../assets/images/dev-filter-icon.svg";
import reload_icon from "../../assets/images/dev-reload_icon.svg";
import { CiSearch } from "react-icons/ci";
import $ from "jquery";
// import 'datatables.net-bs4';
// import '../../../node_modules/datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import { Link } from "react-router-dom";
import { LiaTimesSolid } from "react-icons/lia";
import { FiEdit } from "react-icons/fi";
import Api from "../Api/Api";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { IoClose, IoCloseCircle } from "react-icons/io5";
function Settings() {
  const [isVisible, setIsVisible] = useState(false);
  const [UsersData, setUsersData] = useState([]);
  const [UsersEnd, setUsersEnd] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = UsersData.slice(indexOfFirstRow, indexOfLastRow);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [PerPageCount, setPerPageCount] = useState("");
  const [StartCount, setStartCount] = useState("");
  const [TotalCount, setTotalCount] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const { UsersList } = Api();

  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const dataTableRef = useRef(null);

  // const GetUsersList = async () => {
  //     try {
  //         let data = await UsersList();
  //         setUsersData(data.parameters.data);
  //     } catch (err) {
  //         console.log(err);
  //     }
  // };

  const handlepagination = () => {
    const userName = document.getElementsByName("UserName")[0].value;
    const userEmail = document.getElementsByName("UserEmail")[0].value;

    if ((userName !== "", userEmail !== "")) {
      console.log({ userName, userEmail });
      handleFilterChange({ currentPage: "0" });
      setCurrentPage(1);
    }
  };
  useEffect(() => {
    handlepagination();
  }, []);

  const [first, setFirst] = useState(true);
  const handleFirst = async () => {
    try {
      setFirst(false);
      await handleFilterChange({ currentPage: "0" });
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
      setFirst(true);
    }
  };
  useEffect(() => {
    if (first) {
      handleFirst();
    }
  }, [first]);

  const { LikedMeetings } = Api();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup function to reset the overflow when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const handleFilterChange = async ({ currentPage, user, email }) => {
    try {
      //   const userName = document.getElementsByName("UserName")[0].value;
      //   const userEmail = document.getElementsByName("UserEmail")[0].value;

      setLoading(true);
      //   const isFiltersEmpty = !userName && !userEmail;
      const filters = {
        name: user,
        email: email,
        page: currentPage.toString(),
      };
      const response = await UsersList(filters);
      setUsersData(response.parameters.data);
      setUsersEnd(response.parameters);
      setTotalCount(response.parameters.total);
      setPerPageCount(response.parameters.to);
      setStartCount(response.parameters.from);
      setLoading(false);
      // Handle response here
    } catch (error) {
      setLoading(false);
      console.error("Error occurred during filter:", error);
    }
  };

  // useEffect(() => {
  //     handleFilterChange();
  // }, []);

  useEffect(() => {
    handleFilterChange(currentPage);
  }, [currentPage]);

  const handleNextPage = async () => {
    setCurrentPage(currentPage + 1);
    handleFilterChange({
      currentPage: currentPage + 1,
      user: username,
      email: email,
    });
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };

  const handlePrevPage = async () => {
    setCurrentPage(currentPage - 1);
    handleFilterChange({
      currentPage: currentPage - 1,
      user: username,
      email: email,
    });
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };

  const handleResetFilters = () => {
    // document.getElementsByName("UserName")[0].value = "";
    // document.getElementsByName("UserEmail")[0].value = "";
    setEmail("");
    setUsername("");
    handleFilterChange({
      email: "",
      user: "",
      currentPage: "0",
    });
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
    setCurrentPage(1);
    handleFilterChange({
      email: event.target.value,
      user: username,
      currentPage: "0",
    });
  };
  const handleUsername = (event) => {
    console.log(event);
    setUsername(event.target.value);
    setCurrentPage(1);
    handleFilterChange({
      email: email,
      user: event.target.value,
      currentPage: "0",
    });
  };
  return (
    <div className="page_container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb">
              {/* <ul>
                <li className="active">
                  <Link to="#">Home</Link>
                </li>
                <li>Settings</li>
              </ul> */}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="mob_fil_align align-items-center">
              <div className="page_title">User Management Settings</div>
              <div className="d-flex align-items-center">
                <div className="text-end show_mobile me-3">
                  <Link
                    to="/admin/settings/adduser"
                    className="btn add_user-btn"
                  >
                    ADD USER
                  </Link>
                </div>

                <div
                  className="mobile_filter_icon mob_fills_icon"
                  onClick={toggleVisibility}
                >
                  <img src={filter_icon} alt="filter" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row desktop_filter align-items-center mb-3">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-10 mb-3">
            <form>
              <div className={`filter_container ${isOpen ? "open" : ""}`}>
                <div
                  className="filter_icon filter_col sm_flex-end hide_mobile"
                  onClick={toggleVisibility}
                >
                  <img src={filter_icon} alt="filter" className="" />
                  {/* <IoClose className="show_mobile filter_close" /> */}
                </div>

                <div
                  className="close_sidemenu_mobile"
                  onClick={toggleVisibility}
                >
                  {isOpen && (
                    <IoCloseCircle
                      className="close_icon_mobile_response"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    />
                  )}
                </div>
                <div className="fw-bold mobile-mt-3 filter_title filter_col d-flex align-items-center justify-content-center">
                  Search by
                </div>
                <div className="status_filter filter_col">
                  <div className="name_filter position-relative">
                    <input
                      type="text"
                      placeholder="Name"
                      name="UserName"
                      value={username}
                      onChange={handleUsername}
                    />
                    <span>
                      <CiSearch />
                    </span>
                  </div>
                </div>
                <div className="from_date-filter filter_col">
                  <div className="name_filter position-relative">
                    <input
                      type="text"
                      value={email}
                      placeholder="Email"
                      name="UserEmail"
                      onChange={handleEmail}
                    />
                    <span>
                      <CiSearch />
                    </span>
                  </div>
                </div>

                <div
                  className="white-space-nowrap reset_filter filter_col d-flex align-items-center justify-content-center"
                  onClick={handleResetFilters}
                >
                  <img src={reload_icon} alt="reset" className="me-2" /> Reset
                  Filter
                </div>
              </div>
            </form>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-2 text-end mb-3 hide_mobile">
            <Link to="/admin/settings/adduser" className="btn add_user-btn">
              ADD USER
            </Link>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="table-responsive main_datatable">
              <table
                ref={dataTableRef}
                className="table custom_datatable"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>NAME</th>
                    <th>EMAIL</th>
                    <th>ROLE</th>
                    <th>CREATED DATE</th>
                    <th>STATUS</th>
                    <th className="text-center">ACTION</th>
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan="7" className="text-center">
                        Loading...
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {UsersData && UsersData.length ? (
                      UsersData.map((user, index) => (
                        <tr key={user.id}>
                          <td className="text-center">
                            {indexOfFirstRow + index + 1}
                          </td>
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.role}</td>
                          <td>{user.formatted_created_at}</td>
                          <td>
                            <button
                              className={
                                user.status === 1
                                  ? "btn custom_btn-success ws-75"
                                  : "btn custom_btn-danger ws-75"
                              }
                            >
                              {user.status === 1 ? "Active" : "Inactive"}
                            </button>
                          </td>
                          <td className="text-center">
                            <div className="ft_buttons">
                              <Link
                                to={`/admin/settings/edituser/${user.id}`}
                                className="btn add_user-btn border-0"
                              >
                                <div className="fss_20 text-success">
                                  <FiEdit />
                                </div>
                              </Link>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No meetings found for the selected filters.
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
            {UsersData.length > 0 && (
              <div className="d-flex align-items-center justify-content-between">
                <p className="m-0 table_page-count">
                  Showing {StartCount}-{PerPageCount} of {TotalCount}
                </p>
                <div className="d-flex align-items-center justify-content-end table_nav">
                  <button disabled={currentPage === 1} onClick={handlePrevPage}>
                    <FaAngleLeft />
                  </button>
                  <button
                    disabled={PerPageCount == TotalCount}
                    onClick={handleNextPage}
                  >
                    <FaAngleRight />
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Settings;
