import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import filter_icon from "../../assets/images/dev-filter-icon.svg";
import reload_icon from "../../assets/images/dev-reload_icon.svg";
import "bootstrap-datepicker";
import "../../../node_modules/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import playIcon from "../../assets/images/dev-play_icon.svg";
import fbIcon from "../../assets/images/dev-fb_icon.svg";
import twitterIcon from "../../assets/images/dev-twitter_icon.svg";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import Api from "../Api/Api";
function LikedMeetings() {
  const moment = require("moment");
  const [MeetingData, setMeetingData] = useState([]);
  console.log(MeetingData);
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalCount, setTotalCount] = useState("");
  const rowsPerPage = 10;
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = MeetingData.slice(indexOfFirstRow, indexOfLastRow);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [StartCount, setStartCount] = useState("");
  const [PerPageCount, setPerPageCount] = useState("");
  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };

  const handleNextPage = async () => {
    setCurrentPage(currentPage + 1);
    handleFilterChange({ currentPage: currentPage + 1 });
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };

  const handlePrevPage = async () => {
    setCurrentPage(currentPage - 1);
    handleFilterChange({ currentPage: currentPage - 1 });
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };

  const [first, setFirst] = useState(true);
  const handleFirst = async () => {
    try {
      setFirst(false);
      await handleFilterChange({ currentPage: "0" });
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
      setFirst(true);
    }
  };
  useEffect(() => {
    if (first) {
      handleFirst();
    }
  }, [first]);

  const { LikedMeetings } = Api();

  const dataTableRef = useRef(null);

  const handleFilterChange = async ({ currentPage }) => {
    try {
      // const statusValue = document.getElementById('status').value;
      setLoading(true);

      const filters = {
        page: currentPage.toString(),
      };

      const response = await LikedMeetings(filters);

      setMeetingData(response.parameters.data);
      setTotalCount(response.parameters.total);
      setPerPageCount(response.parameters.to);
      setStartCount(response.parameters.from);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error occurred during filtering meetings:", error);
    }
  };

  return (
    <div className="page_container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb">
              {/* <ul>
                <li className="active">
                  <Link to="#">Home</Link>
                </li>
                <li>Liked Meetings</li>
              </ul> */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="mob_fil_align">
              <div className="page_title">Liked Meetings</div>
            </div>
          </div>
        </div>

        <div className="row desktop_filter">
          <div className="col-12">
            {/* <form>
                            <div className={`filter_container ${isOpen ? 'open' : ''}`}>
                                <div className="filter_icon filter_col sm_flex-end" onClick={toggleVisibility}>
                                    <img src={filter_icon} alt="filter" className='hide_mobile' />
                                    <IoClose className='show_mobile filter_close' />
                                </div>
                                <div className="fw-bold filter_title filter_col d-flex align-items-center justify-content-center">
                                    Filter By
                                </div>
                                <div className="status_filter filter_col">
                                    <select className="form-control" id="status" name="status" onChange={handleFilterChange}>
                                        <option value="">Status</option>
                                        <option value="UPCOMING">Upcoming</option>
                                        <option value="COMPLETED">Completed</option>
                                        <option value="IN-PROGRESS">In-progress</option>
                                    </select>
                                </div>
                                <div className="from_date-filter filter_col">
                                    <input
                                        ref={fromDateRef}
                                        type="text"
                                        className="form-control border-0"
                                        placeholder="From Date"
                                        name='FromDate'
                                        onChange={handleFromDateChange}
                                    />
                                </div>
                                <div className="to_date-filter filter_col">
                                    <input
                                        ref={toDateRef}
                                        type="text"
                                        className="form-control border-0"
                                        placeholder="To Date"
                                        name='ToDate'
                                        onChange={handleToDateChange}
                                    />
                                </div>
                                <div className="from_time-filter filter_col">
                                    <input type="time" name='FromTime' className="form-control" onChange={handleFilterChange} />
                                </div>
                                <div className="to_time-filter filter_col">
                                    <input type="time" name='ToTime' className="form-control" onChange={handleFilterChange} />
                                </div>
                                <div className="reset_filter filter_col d-flex align-items-center justify-content-center" onClick={handleResetFilters}>
                                    <img src={reload_icon} alt="reset" className="me-2" /> Reset Filter
                                </div>
                            </div>
                        </form> */}
          </div>
        </div>

        <div className="row mob_desk_margin">
          <div className="col-12">
            <div className="table-responsive main_datatable">
              <table
                ref={dataTableRef}
                className="table custom_datatable meeting_table"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <th className="text-center">ID</th>
                    <th className="text-start">TITLE</th>
                    <th className="text-start">START TIME</th>
                    <th className="text-start">END TIME</th>
                    <th className="text-center">CREATED DATE</th>
                    <th className="text-center">LIKED MEETINGS</th>
                    <th className="text-center">STATUS</th>
                  </tr>
                </thead>

                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan="7" className="text-center">
                        Loading...
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {MeetingData && MeetingData.length ? (
                      MeetingData.map((meet, index) => (
                        // <Link
                        //   to={`/admin/likedmeetings/meeting-detail/${meet.id}`}
                        // >
                        //   <tr key={meet.id}>
                        //     <td className="text-center">
                        //       {indexOfFirstRow + index + 1}
                        //     </td>
                        //     <td className="text-start">{meet.video_title}</td>
                        //     <td className="text-center">{meet.total_likes}</td>
                        //   </tr>
                        // </Link>
                        <tr key={meet.id}>
                          <td className="text-center">
                            {indexOfFirstRow + index + 1}
                          </td>
                          <td className="text-start">
                            <Link
                              to={`/admin/likedmeetings/meeting-detail/${meet.id}`}
                            >
                              {meet.video_title && meet.video_title}
                            </Link>
                          </td>
                          <td className="text-center">
                            {meet.from_time
                              ? moment(meet.from_time, "HH:mm:ss").format(
                                  "HH:mm"
                                )
                              : "00:00"}
                          </td>
                          <td className="text-center">
                            {meet.to_time
                              ? moment(meet.to_time, "HH:mm:ss").format("HH:mm")
                              : "00:00"}
                          </td>
                          <td className="text-center">
                            {meet.video_date && meet.video_date}
                          </td>
                          <td className="text-center">
                            {meet.total_likes && meet.total_likes}
                          </td>
                          <td className="text-center">
                            <button
                              className={
                                meet.status === "COMPLETED"
                                  ? "btn custom_btn-success ws-75"
                                  : meet.status === "UPCOMING"
                                  ? "btn custom_btn-info ws-75"
                                  : "btn custom_btn-warning ws-75"
                              }
                            >
                              {meet.status === "COMPLETED"
                                ? "Completed"
                                : meet.status === "UPCOMING"
                                ? "Upcoming"
                                : "Live"}
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No data
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="m-0 table_page-count">
                Showing {StartCount}-{PerPageCount} of {TotalCount}
              </p>
              <div className="d-flex align-items-center justify-content-end table_nav">
                <button disabled={currentPage === 1} onClick={handlePrevPage}>
                  <FaAngleLeft />
                </button>
                <button
                  disabled={PerPageCount == TotalCount}
                  onClick={handleNextPage}
                >
                  <FaAngleRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LikedMeetings;
