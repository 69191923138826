import React, { useEffect, useRef, useState } from "react";
import "./LikedVideos.css";
import { Link } from "react-router-dom";
import playIcon from "../../assets/images/dev-play_icon.svg";
import fbIcon from "../../assets/images/dev-fb_icon.svg";
import twitterIcon from "../../assets/images/dev-twitter_icon.svg";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoMdHeart } from "react-icons/io";
import { AiOutlineLike } from "react-icons/ai";
import { AiFillLike } from "react-icons/ai";
import videoImg from "../../assets/images/dev-video_thum.png";
import Api from "../Api/Api";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import VideoDuration from "../util/VideoDuration";
function LikedVideos() {
  const moment = require("moment");
  const [isChecked, setIsChecked] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [FavoriteVideoList, setFavoriteVideoList] = useState([]);
  const [LikedVideoList, setLikedVideoList] = useState(null);
  const [likeCount, setLikeCount] = useState(12);
  //pagination
  const [StartCount, setStartCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalCount, setTotalCount] = useState("");
  const [PerPageCount, setPerPageCount] = useState("");
  const [fevStartCount, setfevStartCount] = useState("");
  const [fevcurrentPage, setfevCurrentPage] = useState(1);
  const [fevTotalCount, setfevTotalCount] = useState("");
  const [fevPerPageCount, setfevPerPageCount] = useState("");
  const rowsPerPage = 10;
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfLastRowfev = fevcurrentPage * rowsPerPage;
  const indexOfFirstRow = Math.max(indexOfLastRow - rowsPerPage, 0);

  const handlePrevPage = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (currentPage > 1) {
      GetLiked({
        currentPage: currentPage - 1,
      });
    }
  };

  const handleNextPage = async () => {
    setCurrentPage(currentPage + 1);
    if (currentPage >= 1) {
      GetLiked({
        currentPage: currentPage + 1,
      });
    }
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };
  const handlePrevfev = async () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
    if (currentPage > 1) {
      GetFavorite({
        currentPage: currentPage - 1,
      });
    }
    if (window.innerWidth > 768) {
      // Adjust 768 according to your design
      window.scrollTo(0, 0);
    }
  };

  const handleNextfev = async () => {
    setCurrentPage(currentPage + 1);
    if (currentPage >= 1) {
      GetFavorite({
        currentPage: currentPage + 1,
      });
    }
  };

  const {
    FavoriteList,
    LikedList,
    AddFavoriteVideo,
    RemoveFavoriteVideo,
    AddLikeVideo,
    RemoveLikeVideo,
  } = Api();
  const [likedVideos, setLikedVideos] = useState({});
  const [favoritedVideos, setFavoritedVideos] = useState({});
  const [loading, setLoading] = useState(true);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleLikeChange = () => {
    setIsLike(!isLike);
    if (!isLike) {
      setLikeCount(likeCount + 1);
    } else {
      setLikeCount(likeCount - 1);
    }
  };

  const GetFavorite = async ({ currentPage }) => {
    let body = {
      page: currentPage,
    };
    try {
      let data = await FavoriteList(body);
      setFavoriteVideoList(data.parameters.data);
      setfevTotalCount(data.parameters.total);
      setfevPerPageCount(data.parameters.to);
      setfevStartCount(data.parameters.from);
    } catch (err) {
      console.log(err);
    }
  };

  const GetLiked = async ({ currentPage }) => {
    let body = {
      page: currentPage,
    };
    try {
      let data = await LikedList(body);
      setLikedVideoList(data.parameters?.data);
      setTotalCount(data.parameters.total);
      setPerPageCount(data.parameters.to);
      setStartCount(data.parameters.from);
      // setcatalogData(data.categories);
    } catch (err) {
      console.log(err);
    }
  };

  // Update the liked status of a video

  const handleLikeChangee = async ({ videoId, videocheck }) => {
    setLikedVideos((prevState) => ({
      ...prevState,
      [videoId]: !prevState[videoId],
    }));
    try {
      if (videocheck === 0) {
        await AddLikeVideo(videoId);
      } else {
        await RemoveLikeVideo(videoId);
      }
      GetLiked({ currentPage: "1" });
      GetFavorite({ currentPage: "1" });
    } catch (err) {
      console.log(err);
    }
  };

  // Update the favorited status of a video

  const handleFavoriteChange = async ({ videoId, videocheck }) => {
    setFavoritedVideos((prevState) => ({
      ...prevState,
      [videoId]: !prevState[videoId],
    }));

    try {
      if (videocheck === 0) {
        await AddFavoriteVideo(videoId);
      } else {
        await RemoveFavoriteVideo(videoId);
      }
      GetFavorite({ currentPage: "1" });
      GetLiked({ currentPage: "1" });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetFavorite({ currentPage: "1" });
    GetLiked({ currentPage: "1" });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Start loading
        await GetFavorite({ currentPage: "1" });
        GetLiked({ currentPage: "1" });
        setLoading(false); // Stop loading
      } catch (err) {
        console.log(err);
        setLoading(false); // Stop loading in case of error
      }
    };
    fetchData();
  }, []);

  return (
    <div className="page_container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="breadcrumb">
              {/* <ul>
                                <li className='active'><Link to="#">Home</Link></li>
                                <li>Liked Meetings</li>
                            </ul> */}
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-12">
            <div className="d-flex align-items-center justify-content-between liked_videos-title">
              <div className="page_title   pb-0">Liked Meetings</div>
              <div>
                <ul
                  className="nav nav-tabs border-0 liked_videos-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#LikedVideos"
                    >
                      Liked Meetings
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#Favourites"
                    >
                      Subscribed Meetings
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {loading ? (
          <div className="loader text-center">Loading data...</div>
        ) : (
          <div className="row">
            <div className="col-12">
              <div className="tab-content">
                <div id="LikedVideos" className="tab-pane active">
                  <div className="row mt-3">
                    {LikedVideoList && LikedVideoList.length > 0 ? (
                      LikedVideoList.map((video, index) => (
                        <>
                          <div className="col-12 col-sm-6 col-md-4 mb-3 mob_padding">
                            <div className="video_blog-card">
                              <div className="video_banner mb-3 position-relative">
                                <img
                                  src={
                                    video.cover_link
                                      ? video.cover_link
                                      : videoImg
                                  }
                                  alt="video banner"
                                />
                                <label
                                  className="like_video"
                                  htmlFor={video.id + "Favorite"}
                                >
                                  <input
                                    id={video.id + "Favorite"}
                                    type="checkbox"
                                    checked={video.is_users_favorite === 1}
                                    onChange={() =>
                                      handleFavoriteChange({
                                        videoId: video.id,
                                        videocheck: video.is_users_favorite,
                                      })
                                    }
                                  />
                                  {video.is_users_favorite === 1 ? (
                                    <IoMdHeart />
                                  ) : (
                                    <IoMdHeartEmpty />
                                  )}
                                </label>
                                <label
                                  className="thump_like"
                                  htmlFor={video.id + "Like"}
                                >
                                  <input
                                    id={video.id + "Like"}
                                    type="checkbox"
                                    checked={likedVideos[video.id]}
                                    onChange={() =>
                                      handleLikeChangee({
                                        videoId: video.id,
                                        videocheck: video.is_user_liked,
                                      })
                                    }
                                  />
                                  {video.is_user_liked === 1 ? (
                                    <AiFillLike className="like_icon" />
                                  ) : (
                                    <AiOutlineLike className="like_icon" />
                                  )}
                                  <span className="like_count">
                                    {video.total_likes}
                                  </span>
                                </label>
                              </div>
                              <div className="video_description mb-3">
                                <Link
                                  to={`/user/likedvideos/meeting-detail/${video.id}`}
                                  className="text-decoration-none"
                                >
                                  <div className="video_title">
                                    {video.video_title}
                                  </div>
                                  <div className="video_duration d-flex">
                                    <VideoDuration
                                      from_time={
                                        video.from_time
                                          ? video.from_time
                                          : "00:00"
                                      }
                                      to_time={
                                        video.to_time ? video.to_time : "00:00"
                                      }
                                    />
                                    <span className="px-2"></span>(
                                    {video.from_time
                                      ? moment(
                                          video.from_time,
                                          "HH:mm:ss"
                                        ).format("HH:mm")
                                      : "00:00"}
                                    -
                                    {video.to_time
                                      ? moment(
                                          video.to_time,
                                          "HH:mm:ss"
                                        ).format("HH:mm")
                                      : "00:00"}
                                    )
                                  </div>
                                </Link>
                              </div>
                              <div className="video_other d-flex justify-content-between align-items-center">
                                {video.video_date ? (
                                  <div className="video_date">
                                    Date : {video.video_date}
                                  </div>
                                ) : null}
                                <div>
                                  <span className="video_date pe-2">
                                    Status :
                                  </span>
                                  <button
                                    className={
                                      video.status === "COMPLETED"
                                        ? "btn custom_btn-success ws-75"
                                        : video.status === "UPCOMING"
                                        ? "btn custom_btn-info ws-75"
                                        : "btn custom_btn-warning ws-75"
                                    }
                                  >
                                    {video.status === "COMPLETED"
                                      ? "Completed"
                                      : video.status === "UPCOMING"
                                      ? "Upcoming"
                                      : "Live"}
                                  </button>
                                </div>
                                <div className="video_social-media">
                                  <ul className="action_list">
                                    {video?.youtube_link ? (
                                      <li>
                                        <Link
                                          to={video.youtube_link}
                                          target="_blank"
                                        >
                                          <img src={playIcon} alt="play" />
                                        </Link>
                                      </li>
                                    ) : null}
                                    {video?.facebook_link ? (
                                      <li>
                                        <Link
                                          to={video.facebook_link}
                                          target="_blank"
                                        >
                                          <img src={fbIcon} alt="facebook" />
                                        </Link>
                                      </li>
                                    ) : null}
                                    {video?.twitter_link ? (
                                      <li>
                                        <Link
                                          to={video.twitter_link}
                                          target="_blank"
                                        >
                                          <img
                                            src={twitterIcon}
                                            alt="twitter"
                                          />
                                        </Link>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="row">
                        <div className="col-12 col-sm-6 m-auto">
                          <div className="text-center alert alert-danger">
                            There are no liked meetings
                          </div>
                        </div>
                      </div>
                    )}
                    {LikedVideoList && LikedVideoList.length > 0 && (
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="m-0 table_page-count">
                          Showing {StartCount}-{PerPageCount} of {TotalCount}
                        </p>
                        <div className="d-flex align-items-center justify-content-end table_nav">
                          <button
                            disabled={currentPage === 1}
                            onClick={handlePrevPage}
                          >
                            <FaAngleLeft />
                          </button>
                          <button
                            disabled={PerPageCount == TotalCount}
                            onClick={handleNextPage}
                          >
                            <FaAngleRight />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div id="Favourites" className="tab-pane fade">
                  <div className="row mt-3">
                    {FavoriteVideoList && FavoriteVideoList.length > 0 ? (
                      FavoriteVideoList.map((video, index) => (
                        <>
                          <div
                            className="col-12 col-sm-6 col-md-4 mb-3 mob_padding"
                            key={video.id}
                          >
                            <div className="video_blog-card">
                              <div className="video_banner mb-3 position-relative">
                                <img
                                  src={
                                    video.cover_link
                                      ? video.cover_link
                                      : videoImg
                                  }
                                  alt="video banner"
                                />
                                <label
                                  className="like_video"
                                  htmlFor={video.id + "Favorite"}
                                >
                                  <input
                                    id={video.id + "Favorite"}
                                    type="checkbox"
                                    checked={video.is_users_favorite === 1}
                                    onChange={() =>
                                      handleFavoriteChange({
                                        videoId: video.id,
                                        videocheck: video.is_users_favorite,
                                      })
                                    }
                                  />
                                  {video.is_users_favorite === 1 ? (
                                    <IoMdHeart />
                                  ) : (
                                    <IoMdHeartEmpty />
                                  )}
                                </label>
                                <label
                                  className="thump_like"
                                  htmlFor={video.id + "Like"}
                                >
                                  <input
                                    id={video.id + "Like"}
                                    type="checkbox"
                                    checked={likedVideos[video.id]}
                                    onChange={() =>
                                      handleLikeChangee({
                                        videoId: video.id,
                                        videocheck: video.is_user_liked,
                                      })
                                    }
                                  />
                                  {video.is_user_liked === 1 ? (
                                    <AiFillLike className="like_icon" />
                                  ) : (
                                    <AiOutlineLike className="like_icon" />
                                  )}
                                  <span className="like_count">
                                    {video.total_likes}
                                  </span>
                                </label>
                              </div>
                              <div className="video_description mb-3">
                                <Link
                                  to={`/user/likedvideos/meeting-detail/${video.id}`}
                                  className="text-decoration-none"
                                >
                                  <div className="video_title">
                                    {video.video_title}
                                  </div>
                                  {/* <div className="video_duration">
                                    2 Hours 15 Mins ({" "}
                                    {video.from_time
                                      ? moment(
                                          video.from_time,
                                          "HH:mm:ss"
                                        ).format("HH:mm")
                                      : "00:00"}
                                    -
                                    {video.to_time
                                      ? moment(
                                          video.to_time,
                                          "HH:mm:ss"
                                        ).format("HH:mm")
                                      : "00:00"}
                                    )
                                  </div> */}
                                  <div className="video_duration d-flex">
                                    <VideoDuration
                                      from_time={
                                        video.from_time
                                          ? video.from_time
                                          : "00:00"
                                      }
                                      to_time={
                                        video.to_time ? video.to_time : "00:00"
                                      }
                                    />
                                    <span className="px-2"></span>(
                                    {video.from_time
                                      ? moment(
                                          video.from_time,
                                          "HH:mm:ss"
                                        ).format("HH:mm")
                                      : "00:00"}
                                    -
                                    {video.to_time
                                      ? moment(
                                          video.to_time,
                                          "HH:mm:ss"
                                        ).format("HH:mm")
                                      : "00:00"}
                                    )
                                  </div>
                                </Link>
                              </div>
                              <div className="video_other d-flex justify-content-between align-items-center">
                                {video.video_date ? (
                                  <div className="video_date">
                                    Date : {video.video_date}
                                  </div>
                                ) : null}
                                <div>
                                  <span className="video_date pe-2">
                                    Status :
                                  </span>
                                  <button 
                                    className={
                                      video.status === "COMPLETED"
                                        ? "btn custom_btn-success ws-75"
                                        : video.status === "UPCOMING"
                                        ? "btn custom_btn-info ws-75"
                                        : "btn custom_btn-warning ws-75"
                                    }
                                  >
                                    {video.status === "COMPLETED"
                                      ? "Completed"
                                      : video.status === "UPCOMING"
                                      ? "Upcoming"
                                      : "Live"}
                                  </button>
                                </div>
                                <div className="video_social-media">
                                  <ul className="action_list">
                                    {video?.youtube_link ? (
                                      <li>
                                        <Link
                                          to={video.youtube_link}
                                          target="_blank"
                                        >
                                          <img src={playIcon} alt="play" />
                                        </Link>
                                      </li>
                                    ) : null}
                                    {video?.facebook_link ? (
                                      <li>
                                        <Link
                                          to={video.facebook_link}
                                          target="_blank"
                                        >
                                          <img src={fbIcon} alt="facebook" />
                                        </Link>
                                      </li>
                                    ) : null}
                                    {video?.twitter_link ? (
                                      <li>
                                        <Link
                                          to={video.twitter_link}
                                          target="_blank"
                                        >
                                          <img
                                            src={twitterIcon}
                                            alt="twitter"
                                          />
                                        </Link>
                                      </li>
                                    ) : null}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="row">
                        <div className="col-12 col-sm-6 m-auto">
                          <div className="text-center alert alert-danger">
                            There are not Subscribed meetings
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {FavoriteVideoList && FavoriteVideoList.length > 0 && (
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="m-0 table_page-count">
                        Showing {fevStartCount}-{fevPerPageCount} of{" "}
                        {fevTotalCount}
                      </p>
                      <div className="d-flex align-items-center justify-content-end table_nav">
                        <button
                          disabled={fevcurrentPage === 1}
                          onClick={handlePrevfev}
                        >
                          <FaAngleLeft />
                        </button>
                        <button
                          disabled={fevPerPageCount == fevTotalCount}
                          onClick={handleNextfev}
                        >
                          <FaAngleRight />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LikedVideos;
